<template>
  <div>
    <div class="flex mt-3 gap-4 md:gap-0 justify-between">
      <div>
        <BInputGroup class="input-group-merge">
          <BInputGroupPrepend is-text>
            <b-img
              fluid
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
              alt="search"
            />
          </BInputGroupPrepend>
          <BFormInput
            v-model="broadcast.search"
            size="md"
            placeholder="Cari berdasarkan judul"
            style="padding: 8px 1rem"
            @input="handleSearchReceiver"
          />
        </BInputGroup>
      </div>
      <div>
        <b-button
          variant="outline-primary"
          to="/broadcast/history"
        >
          Riwayat Broadcast
        </b-button>
      </div>
    </div>
    <b-row class="mt-2">
      <b-col>
        <BOverlay
          :show="broadcast.loading"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BTable
            id="scroll"
            ref="scroll"
            :items="items"
            :fields="fieldsBroadcastForBrandsTable"
            show-empty
            empty-text="Tidak ada data yang ditampilkan."
            responsive
            sticky-header="500px"
            class="mt-1"
            @scroll.native="handleScroll"
          >
            <template #cell(title)="data">
              <div class="text-black text-capitalize">
                {{ capitalize(replaceUnderscoreWithSpaces(data.item.title)) }}
              </div>
            </template>
            <template #cell(template_type)="data">
              <div class="text-black text-capitalize">
                {{ capitalize(data.item.template_type) }}
              </div>
            </template>
            <template #cell(contact_total)="data">
              <span class="text-black">{{ data.item.total }}</span>
            </template>
            <template #cell(status)="data">
              <div>
                <div
                  :class="{'bg-[#FFEDED]': data.item.status === 'REJECTED','bg-[#DCF3EB]': data.item.status === 'APPROVED', 'bg-[#FFF2E2]': data.item.status === 'PENDING' }"
                  class=" rounded-lg py-[4px]"
                >
                  <div
                    :class="{'text-[#E31A1A]': data.item.status === 'REJECTED', 'text-[#34A770]': data.item.status === 'APPROVED', 'text-[#FBA63C]': data.item.status === 'PENDING' }"
                    class="text-center text-capitalize  font-[500]"
                  >
                    {{ capitalize(data.item.status) }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(action)="data">
              <b-button
                :variant="data.item.status !== 'APPROVED'? 'outline-secondary':'primary'"
                size="sm"
                :disabled="data.item.status !== 'APPROVED'"
                class="w-[78px] !flex !items-center !justify-center h-[29px]"
                @click="handleActionBroadcast(data.item.id)"
              >
                <div class="flex justify-center gap-1 items-center">
                  <img
                    :src="data.item.status === 'APPROVED'?'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/send-2.svg' : 'https://storage.googleapis.com/komerce/assets/LP-Komchat/send-uhuy.svg'"
                    alt="icon-send"
                    width="16"
                    srcset=""
                  >
                  <div class="">
                    Blast
                  </div>
                </div>
              </b-button>

            </template>
          </BTable>
        </BOverlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { fieldsBroadcastForBrandsTable } from '../config'
import { apiListBroadcastForBrand, apiSendBroadcastForBrand } from '../service/api.broadcast.service'

export default {
  data() {
    return {
      fieldsBroadcastForBrandsTable,
      items: [],
      broadcast: {
        offset: '',
        limit: 10,
        search: '',
        loading: false,
        lastData: false,
        debounce: 0,
      },
    }
  },
  mounted() {
    this.getDataBroadcast()
  },
  methods: {
    async getDataBroadcast() {
      // Check if it's initial load based on this.items
      const isInitial = !this.items || this.items.length === 0

      // Return early if trying to load next data but reached the end
      if (!isInitial && this.broadcast.lastData) {
        return
      }

      this.broadcast.loading = true

      try {
        const { data } = await apiListBroadcastForBrand(
          this.broadcast.limit,
          this.broadcast.offset,
          this.broadcast.search,
        )
        const items = data.data

        // Set or append items based on isInitial check
        if (isInitial) {
          this.items = items
        } else {
          this.items.push(...items)
        }
        this.broadcast.offset = this.items.length
        this.broadcast.lastData = items.length < this.broadcast.limit
      } catch (error) {
        console.error(error)
        this.$toast_error({
          message: this.capitalize(error.response.data.meta.message),
        })
      } finally {
        this.broadcast.loading = false
      }
    },
    async handleActionBroadcast(id) {
      const response = await this.$swal({
        title: 'Kirim Broadcast',
        html: '<span class="my-2">Apakah anda yakin ingin mengirim broadcast ini?<span>',
        confirmButtonText: 'Kirim',
        icon: 'question',
        width: 500,
        confirmButtonClass: 'btn btn-primary',
        showCancelButton: true,
        cancelButtonText: 'Batal',
        cancelButtonClass: 'btn btn-secondary',
        showLoaderOnConfirm: true,
        preConfirm: () => new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 2000)
        }),
        allowOutsideClick: () => !this.$swal.isLoading(),
      })

      if (response.isConfirmed) {
        try {
          await apiSendBroadcastForBrand(id)
          this.$toast_success({
            message: 'Berhasil mengirim broadcast',
          })
          this.getDataBroadcast()
        } catch {
          this.$toast_error({
            message: 'Gagal mengirim broadcast',
          })
        }
      }
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        this.getDataBroadcast()
      }
    },
    handleSearchReceiver() {
      clearTimeout(this.broadcast.debounce)

      // eslint-disable-next-line no-underscore-dangle
      this.broadcast.debounce = setTimeout(() => {
        this.getDataBroadcast()
      }, 2000)
    },
    replaceUnderscoreWithSpaces(str) {
      return str.replace(/_/g, ' ')
    },
    capitalize(str) { return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') },
  },
}
</script>
